<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-01-24 08:54:29
 * @LastEditors: ------
 * @LastEditTime: 2022-02-24 09:19:35
-->
<template>
  <div class="home_container">
    <van-nav-bar title="首页" safe-area-inset-top placeholder   right-text="登出"  @click-right="onClickRight"/>

    <div class="home_box three" @click="$router.push('/create')">
      <span>创建访谈</span>
    </div>
    <div class="home_box two" @click="$router.push('/customer')">
      <span>客户管理</span>
    </div>
    <div class="home_box one" @click="$router.push('/file')">
      <span>护理员档案</span>
    </div>
  </div>
</template>

<script>

import { logout } from "@/api";
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
  },
  // 方法集合
  methods: {
    onClickRight(){
      logout(); 
      this.$router.push({
          path: "/login",
        });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  // 这里存放数据
};
</script>
<style lang="less" scoped>
.home_box {
  width: 317px;
  height: 149px;
  border-radius: 8px;
  text-align: center;
  margin: auto;
  margin-top: 30px;
  span {
    display: block;
    width: 50%;
    line-height: 149px;
    font-size: 24px;
    color: #fff;
    margin-left: 6px;
  }
}
.one {
  background: url("../../images/53.png") no-repeat;
  background-size: 100% 100%;
}
.two {
  background: url("../../images/54.png") no-repeat;
  background-size: 100% 100%;
}
.three {
  background: url("../../images/55.png") no-repeat;
  background-size: 100% 100%;
}
</style>
